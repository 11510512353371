import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
import { fetchpost } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------
RecordFormdel.propTypes = {
  onSubmited: PropTypes.func,
  handleClose: PropTypes.func,
  data: PropTypes.object
};

export default function RecordFormdel({ handleClose, data, onSubmited }) {
  console.log('recordId', data.Ref)
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    UPSName: Yup.string().required('Required field').matches(data.UPSName),
  });
  const formik = useFormik({
    initialValues: {
      _id: data._id
    },
    validationSchema: LoginSchema,
    onSubmit: (form) => {
      fetchpost('/app/delete', form).then((response) => {
        enqueueSnackbar(response.message, { variant: response.variant })
        onSubmited()
        handleClose()
      })
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete App Record
          </Typography>
          <Stack alignItems="left" direction="column">
            <Typography>Please enter UPS name</Typography>
            <Stack alignItems="left" direction="row">
              <Typography color="warning.light" onClick={e => { navigator.clipboard.writeText(e.target.textContent) }}> `{data.UPSName}`</Typography>
            </Stack>
            <Typography>to continue.</Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Stack width="100%">
              <TextField
                type="UPSName"
                label="UPSName"
                {...getFieldProps('UPSName')}
                error={Boolean(touched.UPSName && errors.UPSName)}
                helperText={touched.UPSName && errors.UPSName}
              />
            </Stack>
          </Stack>

          <Stack spacing={3} direction="row">
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="info"
              onClick={handleClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" color="error">
              Delete
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
