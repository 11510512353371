import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

import { logout } from '../../middleware/auth';

import Modalpopup from './Modalpopup'

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  userdata: PropTypes.object,
};

export default function AccountPopover({ userdata }) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => setOpen(null);

  const [modalopen, setmodalopen] = useState(false);
  const [modalpage, setmodalpage] = useState('');
  const handlemodalClose = () => setmodalopen(false);

  const handlePasswordOpen = () => {
    setOpen(false);
    setmodalpage('PasswordForm')
    setmodalopen(true)
  };

  return (
    <>
      <Modalpopup modalpage={modalpage} modalopen={modalopen} modalhandleClose={handlemodalClose} />
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userdata.email}
          </Typography>
        </Box>

        <MenuItem onClick={() => { navigate('/', { replace: true }) }} sx={{ m: 1 }}>
          Home
        </MenuItem>

        <MenuItem onClick={() => handlePasswordOpen()} sx={{ m: 1 }}>
          Password
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />


        <MenuItem onClick={() => { logout(); enqueueSnackbar('logged out', { variant: 'info' }); navigate('/', { replace: true }); }} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
