import PropTypes from 'prop-types';
import { useWindowSize } from 'usehooks-ts'
// material
import { Box, Modal } from '@mui/material';

import { AppForm, AppFormdel, AppFormedit } from '../../components/authentication/Apps';
import { AdminFormdel } from '../../components/authentication/admin';
import { PasswordForm } from '../../components/authentication/password';
import { RecordForm, RecordFormdel } from '../../components/_dashboard/records';

// ----------------------------------------------------------------------

Modalpopup.propTypes = {
  onSubmited: PropTypes.func,
  modalpage: PropTypes.string,
  modaldata: PropTypes.object,
  modalopen: PropTypes.bool,
  modalhandleClose: PropTypes.func,
};

export default function Modalpopup({ modalpage, modaldata, modalopen, modalhandleClose, onSubmited }) {
  const { width } = useWindowSize();
  // const [open, setOpen] = useState(true);
  // const handleClose = () => setOpen(false);
  const modalwidth = Math.min(0.8 * width, 800)
  return (
    <Modal
      open={modalopen}
      onClose={modalhandleClose}
      BackdropProps={{
        style: {
          opacity: '0.5'
        }
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: modalwidth,
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #999',
        boxShadow: 'none',
        p: 4
      }}>
        {modalpage === 'AppForm' && <AppForm handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'AppFormedit' && <AppFormedit handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'AppFormdel' && <AppFormdel handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'AdminFormdel' && <AdminFormdel handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'PasswordForm' && <PasswordForm handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'RecordForm' && <RecordForm handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        {modalpage === 'RecordFormdel' && <RecordFormdel handleClose={modalhandleClose} data={modaldata} onSubmited={onSubmited} />}
        
      </Box>
    </Modal>
  );
}