import PropTypes from 'prop-types';
import * as React from 'react';
import {
  Drawer,
  IconButton,
} from '@mui/material';

import Iconify from '../../../components/Iconify';
import Report from './Report';

RecordDrawer.propTypes = {
  reportinfo: PropTypes.object,
  // setOpen: PropTypes.func,
};

export default function RecordDrawer({reportinfo}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () =>setOpen(false)
  
  return (
    <React.Fragment key={`drawer-${reportinfo.id}`}>
      <IconButton onClick={handleOpen}>
        <Iconify icon="line-md:document-report" width={20} height={20} />
      </IconButton>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >        
        {open && <Report handleClose={handleClose} reportinfo={reportinfo}/>}
      </Drawer>
    </React.Fragment>
  );
}