import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React from 'react';

import { 
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
 } from '@mui/material';

const StyledTableCell = styled(TableCell)({
  padding: 2,
})

DischargeTablelist.propTypes = {
  labels: PropTypes.array,
  cabinet: PropTypes.string,
  datalist: PropTypes.object,
  handleUpdate: PropTypes.func,
  Title: PropTypes.string,
};

export default function DischargeTablelist({Title,labels,cabinet,datalist,handleUpdate}){  
  const rowlist = []
  const TableHeadlist = ['Time']
  Object.keys(datalist).forEach(key => {
    if(key.includes(cabinet) || !cabinet){
      rowlist.push(datalist[key])
      TableHeadlist.push(key)
    }
  })

  const itemlist = rowlist.map((row)=>{
    const list = []
    const values = JSON.parse(row)
    Object.keys(values).forEach(key => {
    list.push(values[key])
    })
    return list
  })

  const TableRowList = itemlist[0].map((_, colIndex) => {
  const rowdata = [labels[colIndex]]
  itemlist.forEach(row =>{
  rowdata.push(row[colIndex].toFixed(2))})
  return rowdata
  });  

  handleUpdate({Title,TableHeadlist,TableRowList})

  return(          
  <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <TableBody>
            <TableRow
                hover
                key={0}
              >
              {TableHeadlist.map((head,index)=> <StyledTableCell key={index} size='small' align="center">{head}</StyledTableCell>)}
              </TableRow>
          {TableRowList.map((row,index) => 
              <TableRow hover key={index}>
                {row.map((cell,index) => <StyledTableCell key={index} size='small' align="center">{cell}</StyledTableCell> )}                    
              </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}