import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import App from './pages/App';
import Admin from './pages/Admin';
import User from './pages/User';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import OTP from './pages/OTP';
import NotFound from './pages/Page404';
import Register from './pages/Register';

// ----------------------------------------------------------------------

export default function Router() {
  const userdata = JSON.parse(localStorage.getItem('auth'));
  const adminroutes = useRoutes([
    {
      path: '/web',
      element: <DashboardLayout userdata={userdata} />,
      children: [
        { path: 'User', element: <User /> },
        { path: 'Admin', element: <Admin /> },
      ],
    },
    { path: '*', element: <Navigate to="/web/User" replace /> },
  ]);

  const authroutes = useRoutes([
    {
      path: '/web',
      element: <DashboardLayout userdata={userdata} />,
      children: [
        { path: 'App', element: <App /> },
      ],
    },
    { path: '*', element: <Navigate to="/web/App" replace /> },
  ]);

  const unauthroutes = useRoutes([
    {
      path: '/',      
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
        { path: 'Recovery', element: <Recovery /> },
        { path: 'OTP', element: <OTP /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
      ],
    },
    { path: '*', element: <Navigate to="/login" replace /> },
  ]);
  if (!userdata) { return unauthroutes }
  if (userdata.isadminuser) { return adminroutes }
  if (userdata.isAuthenticated) { return authroutes }
}
