import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Stack, TextField, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
// component
import Iconify from '../../../components/Iconify';
import { fetchpost } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [getInstance, setgetInstance] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const NextSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Required field'),
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Required field'),
    password: Yup.string().required('Required field'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: getInstance ? LoginSchema : NextSchema,
    onSubmit: async (form) => {
      if (form.password === '') {
        handleGetInstance()
      } else {
        fetchpost('/users/login', form).then((response) => {
          if (response.variant === 'info') {
            enqueueSnackbar(response.message, { variant: response.variant })
            navigate(`/OTP?instanceid=${response.instanceid}`, { replace: true });
          } else {
            enqueueSnackbar(response.message, { variant: response.variant })
            setisSubmitting(false);
          }
        })
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleGetInstance = () => {
    setgetInstance(true)
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        {getInstance ? (
          < Stack spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Stack direction="row" justifyContent="space-between" >
              <Typography sx={{ color: 'text.secondary' }}>&nbsp;</Typography>
              <Link align="right" sx={{ color: 'info.main' }} href="/Recovery">Forgot password?</Link>
            </Stack>
            <LoadingButton size="large" align="center" type="submit" variant="contained" loading={isSubmitting} >
              Login
            </LoadingButton>
          </Stack>
        ) : (
          < Stack spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleGetInstance} edge="end">
                      <Iconify icon={'ant-design:arrow-right-outlined'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <Stack direction="row" justifyContent="space-between" >
              <Typography sx={{ color: 'text.secondary' }}>&nbsp;</Typography>
              <Link align="right" sx={{ color: 'info.main' }} href="/Recovery">Forgot password?</Link>
            </Stack>
          </Stack>
        )}


      </Form>
    </FormikProvider >
  );
}
