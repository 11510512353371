import React from 'react';
import Iframe from 'react-iframe'
import { useWindowSize } from 'usehooks-ts'

// components
import Page from '../components/Page';


function Admin() {
  const { height } = useWindowSize();
  return (
    <Page title="App">
      <Iframe url={`/nodered`}
        frameBorder="0"
        width="100%"
        height={height - 75} />
    </Page >
  );
}

export default Admin;