import { fetchget } from './fetchapi';
/* Authetifacation actions */
// LOGOUT
export const logout = () => {
  fetchget('/users/logout').then(
    localStorage.removeItem('auth')
  );
};

// LOGIN STATUS
export const isLogin = () => {
  if (localStorage.getItem('auth')) return true;
  return false;
};
