import PropTypes from 'prop-types';
import React from 'react'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
import { fetchpost } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------
AppFormedit.propTypes = {
  onSubmited: PropTypes.func,
  handleClose: PropTypes.func,
  data: PropTypes.object
};

export default function AppFormedit({ onSubmited, handleClose, data }) {
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    Appname: Yup.string().max(20, 'Too Long!').required('Required field'),
    url: Yup.string().required('Required field'),
    location: Yup.string().required('Required field'),
  });
  const formik = useFormik({
    initialValues: {
      Appname: data.Appname,
      url: data.url,
      location: data.location,
      _id: data._id,
      userId: data.userId
    },
    validationSchema: LoginSchema,
    onSubmit: (form) => {
      fetchpost('/app/edit', form).then((response) => {
        enqueueSnackbar(response.message, { variant: response.variant })
        onSubmited()
        handleClose()
      })
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit App Record
          </Typography>
          <Typography variant="subtitle2">App Name</Typography>
          <TextField
            autoComplete="Appname"
            type="Appname"
            label="Appname"
            {...getFieldProps('Appname')}
            error={Boolean(touched.Appname && errors.Appname)}
            helperText={touched.Appname && errors.Appname}
          />

          <Typography variant="subtitle2">Url</Typography>
          <TextField
            autoComplete="Url"
            type="Url"
            label="Url"
            {...getFieldProps('url')}
            error={Boolean(touched.url && errors.url)}
            helperText={touched.url && errors.url}
          />

          <Typography variant="subtitle2">Location</Typography>
          <TextField
            autoComplete="Location"
            type="Location"
            label="Location"
            {...getFieldProps('location')}
            error={Boolean(touched.location && errors.location)}
            helperText={touched.location && errors.location}
          />

          <Stack spacing={3} direction="row">
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="info"
              onClick={handleClose}
            >
              Close
            </LoadingButton>
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
