import PropTypes from 'prop-types';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

DischargeChart.propTypes = {
  cabinet: PropTypes.string,
  labels: PropTypes.array,
  datalist: PropTypes.object,
};

const colorarray = [
  '#FF0000',
  '#E91E63',
  '#F44336',
  '#FF9800',
  '#FFC107',
  '#FFEB3B',
  '#CDDC39',
  '#8BC34A',
  '#4CAF50',
  '#009688',
  '#00BCD4',
  '#03A9F4',
  '#2196F3',
  '#3F51B5',
  '#673AB7',
  '#9C27B0',
]

export default function DischargeChart({cabinet,labels,datalist}) {

  function _datasets(datalist) {
    const datasets = []
    Object.keys(datalist).forEach(key => {
      if(key.includes(cabinet) || !cabinet){
        const values = JSON.parse(datalist[key])
        const data = []    
    
      
      Object.keys(values).forEach(k => {
        data.push(values[k])
      })
    
      datasets.push(
        {
          label: key,
          data,
          borderColor: colorarray[datasets.length],
          backgroundColor: colorarray[datasets.length],
        }
      )     
      }
    })
    return datasets
  }

  const datasets = _datasets(datalist)

  const data = {
    labels,
    datasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: false,
        ticks:{
          maxTicksLimit: 1000,
        }        
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",
      },
    },
    devicePixelRatio: 2,
    responsive:true,
  };
  
  return (
    <Line options={options} data={data}/>
  )  
  ;
}