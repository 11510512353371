import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { DropzoneAreaBase } from "material-ui-dropzone";
import { useSnackbar } from 'notistack';
import { AttachFile } from '@material-ui/icons';
import CheckIcon from '@mui/icons-material/Check';
import { fetchpostupload } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------
RecordForm.propTypes = {
  onSubmited: PropTypes.func,
  handleClose: PropTypes.func,
};

export default function RecordForm({ handleClose, onSubmited }) {
  const [isupload, setUploaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ isSubmitting, setisSubmitting] = useState(false);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const LoginSchema = Yup.object().shape({
    Site: Yup.string().required('Required field'),
    UPSName: Yup.string().required('Required field'),
  });

  const formik = useFormik({
    initialValues: {
      Site: '',
      UPSName: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (form) => {
      if(isupload) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append('data', JSON.stringify(form))
      fetchpostupload('/app/Add', formData).then((response) => {
        enqueueSnackbar(response.message, { variant: response.variant })
        onSubmited();
        handleClose();
        setisSubmitting(false)
      })      
    }}
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleChange = (e) => {
    setFile(e[0].file)
    setFileName(e[0].file.name);
    setUploaded(true)
    enqueueSnackbar(`${e[0].file.name} selected`, { variant: 'success' })
  }
  return (
    
    <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Record
        </Typography>

        <Box >
        <h3>Upload Fault Data</h3>
        <DropzoneAreaBase Icon={!file ? (AttachFile) : (CheckIcon) } showAlerts={false} useChipsForPreview dropzoneText={!file ? ('‎') : (fileName)} onAdd={handleChange} acceptedFiles={['application/x-compressed/,.tgz']} filesLimit={1} maxFileSize={5000000}/>
        </Box>

        <TextField
          autoComplete="Site"
          type="Site"
          label="Site"
          {...getFieldProps('Site')}
          error={Boolean(touched.Site && errors.Site)}
          helperText={touched.Site && errors.Site}
        />

        <TextField
          autoComplete="UPSName"
          type="UPSName"
          label="UPS Name"
          {...getFieldProps('UPSName')}
          error={Boolean(touched.UPSName && errors.UPSName)}
          helperText={touched.UPSName && errors.UPSName}
        />

        <Stack spacing={3} direction="row">
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            color="info"
            onClick={handleClose}
          >
            Close
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Add
          </LoadingButton>
        </Stack>
      </Stack>
    </Form>
    </FormikProvider>
  );
}
