import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { fetchpost } from '../../../middleware/fetchapi';
// ----------------------------------------------------------------------

export default function RecoveryForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setisSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Required field'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (form) => {
      console.log(form)
      fetchpost('/users/recovery', form).then((response) => {
        if (response.variant !== 'error') {
          enqueueSnackbar(response.message, { variant: response.variant })
          navigate('/login', { replace: true });
        } else {
          enqueueSnackbar(response.message, { variant: response.variant })
          setisSubmitting(false);
        }
      })
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack direction="row" alignItems="right" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton fullWidth size="large" align="center" type="submit" variant="contained" loading={isSubmitting}>
            Confirm
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
