
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
// @mui
import { Grid, Container,
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  } from '@mui/material';
import { jsPDF as JSPDF } from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from 'notistack';
// import Iconify from '../components/Iconify';
// sections
import Iconify from '../../../components/Iconify';
import DischargeTablelist  from './DischargeTablelist';
import DischargeChart  from './DischargeChart';

import smartlidata from '../../../json/SmartLi_FaultInfo_20220526100412.json'

import Logo from  "../../../logo/Huawei-Logo-PNG.png";

import { fetchget } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------

const Tablelist = []

function handleUpdate(list){
  Tablelist.push(list)  
  console.log(Tablelist.length)
}

Report.propTypes = {
  handleClose: PropTypes.func,
  reportinfo: PropTypes.object,
  // setOpen: PropTypes.func,
};

export default function Report({handleClose, reportinfo}) {

  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();

  const [data, setdata] = React.useState(smartlidata);

  const [isupdated, setupdated] = React.useState(false);

  useEffect(()=>{
    fetchget(`/app/record?_id=${reportinfo._id}`).then((response) => {
      if(response.variant === 'error') {return enqueueSnackbar(response.message, { variant: response.variant })}
      enqueueSnackbar(response.message, { variant: response.variant })
      setupdated(true)
      Tablelist.splice(0, Tablelist.length)
      return setdata(response.list)
    })    
  },[enqueueSnackbar,reportinfo._id])

  const Timelist = JSON.parse(data.Timelist)

  const {SystemInfo} = data

  const TimeLabels = []

  Object.keys(Timelist).forEach(key => {
    const date = new Date(new Date(Timelist[key] - 8*60*60*1000))
    const hour = (`0${(date.getHours())}`).slice(-2)
    const minute = (`0${(date.getMinutes())}`).slice(-2)
    const second = (`0${(date.getSeconds())}`).slice(-2)
    TimeLabels.push(`${hour}:${minute}:${second}`)
  })

  const cabinetlist = []

  Object.keys(data.CellTemp).forEach(key => {
  if(!cabinetlist.includes(key.split('-')[0])){
    cabinetlist.push(key.split('-')[0])
  }
  })


  function handleDownloadPdf(){    
    const canvaslist = document.getElementsByTagName('canvas')
    const pdf = new JSPDF();

    // infopage

    pdf.addImage(Logo,'png',5,3,10,10, undefined,'FAST');
    pdf.setFontSize(10);
    pdf.text(20, 10, "Battery Discharge Report | System Information",);
    pdf.setFontSize(8);
    pdf.text(10, 290, `Generated : ${SystemInfo.GenTime}`);
    pdf.text(10, 293, `Ver 1.1`);
    pdf.text(74, 293, 'Copyright 2019-22 Huawei Technologies Co., Ltd.');      
    pdf.text(185, 290, `Page ${(1)} of ${canvaslist.length + 1}`,);
    const tablelist = [['Time: '          ,SystemInfo.GenTime     ,'Start Temp.'                 ,SystemInfo.StartTemp                           ,'°C'   ,'Logged Duration'  ,SystemInfo.Duration                              ,'mm:ss'  ],
                      ['Status'           ,'Discharge'            ,'End Temp.'                   ,SystemInfo.EndTemp                             ,'°C'   ,'Average Current'  ,parseFloat(SystemInfo.AvgCurrent,10).toFixed(2)    ,'A'      ],
                      ['Start Time'       ,SystemInfo.StartTime   ,'Average Cell End Temp.'      ,parseFloat(SystemInfo.EndAvgTemp,10).toFixed(2)  ,'°C'   ,'Average Voltage'  ,parseFloat(SystemInfo.AvgVoltage,10).toFixed(2)    ,'V'      ],
                      ['End Time: '       ,SystemInfo.EndTime     ,'Average Cell End Volt.'         ,parseFloat(SystemInfo.EndAvgVolt,10).toFixed(2)  ,'V'    ,'Average Power'    ,parseFloat(SystemInfo.AvgPower,10).toFixed(2)    ,'kW'       ]
                    ]
                    
                    
    
    pdf.autoTable({
      body: tablelist,
      startY: 20,
      styles: {
        fontSize: 8,
        cellPadding: 0.5,
      },
      columnStyles: {
        0: {fillColor: "#EEEEEE"},
        1: {fillColor: "#FFFFFF",halign : 'center'},
        2: {fillColor: "#EEEEEE"},
        3: {fillColor: "#FFFFFF",halign : 'center',},
        4: {fillColor: "#FFFFFF",cellWidth:10,halign : 'center',},
        5: {fillColor: "#EEEEEE"},
        6: {fillColor: "#FFFFFF",halign : 'center'},
        7: {fillColor: "#FFFFFF",cellWidth:10,halign : 'center'},
      },
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0]
      },
    })

    pdf.autoTable({
      head: [['Cabinent ID','SN','BarCode','Manufactured','Model']],
      body: data.cabinet,
      startY: 50,
      styles: {
        fontSize: 8,
        cellPadding: 0.5,
      },
      headStyles:{
        fillColor: "#CF0A2C"
      },
    })

    pdf.addPage()
    // inforpage
    Object.keys(canvaslist).forEach(key=>{            
      const canvas = canvaslist[key]
      const canvasImage = canvas.toDataURL('image/png')
      const zoom = 18;
      pdf.addImage(Logo,'png',5,3,10,10, undefined,'FAST');
      pdf.setFontSize(10);
      pdf.text(20, 10, "Battery Discharge Report  | ",);
      pdf.text(65, 10, Tablelist[key].Title);
      pdf.setFontSize(8);

      pdf.text(10, 290, `Generated : ${SystemInfo.GenTime}`);      
      pdf.text(10, 293, `Ver 1.1`);
      pdf.text(74, 293, 'Copyright 2019-22 Huawei Technologies Co., Ltd.');      
      pdf.text(185, 290, `Page ${(parseInt(key,10)+2)} of ${canvaslist.length + 1}`,);
      pdf.addImage(canvasImage,'png',25,12,2976/zoom,1488/(zoom), undefined,'FAST');
      pdf.autoTable({
        head: [Tablelist[key].TableHeadlist],
        body: Tablelist[key].TableRowList,
        startY: 95,
        styles: {
          fontSize: 7,
          cellPadding: 0,
          halign : 'center'
        },
        tableLineWidth: 0,      
        headStyles:{
          fillColor: "#CF0A2C"
        },
      },
      )       
      if((canvaslist.length - 1).toString() !== key){pdf.addPage()}
    })
    const fileName = `${reportinfo.Site}_${reportinfo.UPSName}`    
    pdf.setProperties({
      filename: fileName,
      title: fileName,
      subject: "Discharge Report",
      author: "Li Ze Wen",
      keywords: "javascript,Discharge Report, Huawei",
      creator: "li.ze.wen@huawei.com",
      });
    pdf.save(fileName);
    window.open(pdf.output('bloburl', { filename: fileName }), '_blank');
  }

    return (
      <>
      <AppBar position="fixed" >
      <Toolbar>
      <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleClose}
        >
          <Iconify icon="fluent:arrow-left-12-filled" width={30} height={30} />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {reportinfo.Site} | {reportinfo.UPSName} | Discharge Report
        </Typography>
 
        <Button color="inherit" onClick={()=> handleDownloadPdf()}>PDF</Button>

      </Toolbar>
      </AppBar>
      {isupdated &&
        <Container maxWidth="xl" ref={componentRef} sx={{ mt: 12 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge Current by Cabinet
            </Typography>
            <DischargeChart labels={TimeLabels} datalist={data.GroupCurrent}/>
          </Grid>     

          <Grid item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge Voltage by Cabinet
            </Typography>
            <DischargeChart labels={TimeLabels} datalist={data.GroupVolt}/>
          </Grid>     

          <Grid item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge SoC by Cabinet
            </Typography>
            <DischargeChart labels={TimeLabels} datalist={data.GroupSoC}/>
          </Grid>  

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeChart - MV - ${index}`
            return (              
              <Grid key={id} item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
                {`Discharge Module Voltage - Cabinet ${index + 1}`}
              </Typography>
              <DischargeChart cabinet={cabinet} labels={TimeLabels} datalist={data.ModuleVolt}/>
              </Grid>
            )
          })}

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeChart - CV - ${index}`
            return (              
              <Grid key={id} item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              {`Discharge Cell Low Voltage - Cabinet ${index + 1}`}
              </Typography>
              <DischargeChart cabinet={cabinet} labels={TimeLabels} datalist={data.CellVolt}/>
              </Grid>
            )
          })}

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeChart - CT - ${index}`
            return (
              <Grid key={id} item xs={12} sm={12} md={12} style={{ border: "solid 1px"}} sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
                {`Discharge Cell High Tempearture - Cabinet ${index + 1}`}
                </Typography>
                <DischargeChart cabinet={cabinet} labels={TimeLabels} datalist={data.CellTemp}/>
                </Grid>      
            )
          })}

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge Current by Cabinet
            </Typography>
            <DischargeTablelist Title={'Discharge Current by Cabinet'} labels={TimeLabels} datalist={data.GroupCurrent} handleUpdate={handleUpdate} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge Voltage by Cabinet
            </Typography>
            <DischargeTablelist Title={'Discharge Voltage by Cabinet'} labels={TimeLabels} datalist={data.GroupVolt} handleUpdate={handleUpdate} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
              Discharge SoC by Cabinet
            </Typography>
            <DischargeTablelist Title={'Discharge SoC by Cabinet'} labels={TimeLabels} datalist={data.GroupSoC} handleUpdate={handleUpdate} />
          </Grid>

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeTablelist - MV - ${index}`
            return (
              <Grid key={id} item xs={12} sm={12} md={12}>
                <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
                {`Discharge Module Voltage - Cabinet ${index + 1}`}
                </Typography>
                <DischargeTablelist Title={`Discharge Module Voltage - Cabinet ${index + 1}`} cabinet={cabinet} labels={TimeLabels} datalist={data.ModuleVolt} handleUpdate={handleUpdate}/>                  
              </Grid>   
            )
          })}

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeTablelist - CV - ${index}`
            return (
              <Grid key={id} item xs={12} sm={12} md={12}>
                <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
                {`Discharge Cell Low Voltage - Cabinet ${index + 1}`}
                </Typography>
                <DischargeTablelist Title={`Discharge Cell Low Voltage - Cabinet ${index + 1}`} cabinet={cabinet} labels={TimeLabels} datalist={data.CellVolt} handleUpdate={handleUpdate}/>                  
              </Grid>   
            )
          })}

          {cabinetlist.map((cabinet,index)=>{
            const id = `DischargeTablelist - CT - ${index}`
            return (
              <Grid key={id} item xs={12} sm={12} md={12}>
                <Typography variant="h6" sx={{ flexGrow: 1,mb:2}}>
                  {`Discharge Cell High Tempearture - Cabinet ${index + 1}`}
                </Typography>
                <DischargeTablelist Title={`Discharge Cell High Tempearture - Cabinet ${index + 1}`} cabinet={cabinet} labels={TimeLabels} datalist={data.CellTemp} handleUpdate={handleUpdate} />
              </Grid>   
            )
          })}
          
        </Grid>        
      </Container>
      }
      </>
     
  );
}
