import React from 'react';
import { SnackbarProvider } from 'notistack';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <SnackbarProvider preventDuplicate autoHideDuration={2500} maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
