import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
// component
import { fetchpost } from '../../../middleware/fetchapi';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setisSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Required field'),
    newpassword: Yup.string().required('Required field'),
    repassword: Yup.string().required('Required field'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newpassword: '',
      repassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (form) => {
      if (form.password === form.newpassword) { return enqueueSnackbar('New password unchanged', { variant: 'error' }) }
      if (form.newpassword !== form.repassword) { return enqueueSnackbar('New password mismatch', { variant: 'error' }) }

      return fetchpost('/users/password', form).then((response) => {
        if (response.variant === 'success') {
          enqueueSnackbar(response.message, { variant: response.variant })
          navigate(`/`, { replace: true });
        } else {
          enqueueSnackbar(response.message, { variant: response.variant })
          setisSubmitting(false);
        }
      })

    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          Change Password
        </Typography>

        <Stack spacing={2}>
          <TextField
            fullWidth
            type={'password'}
            label="Current Password"
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type={'password'}
            label="New Password"
            {...getFieldProps('newpassword')}
            error={Boolean(touched.newpassword && errors.newpassword)}
            helperText={touched.newpassword && errors.newpassword}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={'password'}
            label="Confirm password"
            {...getFieldProps('repassword')}
            error={Boolean(touched.repassword && errors.repassword)}
            helperText={touched.repassword && errors.repassword}
          />
        </Stack>

        <Stack direction="row" alignItems="right" justifyContent="space-between" sx={{ my: 2 }}>

          <LoadingButton fullWidth size="large" align="center" type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
