import { filter } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,

  IconButton,
  Switch,

} from '@mui/material';

import { useSnackbar } from 'notistack';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { logout } from '../middleware/auth';
import { fetchget, fetchpost } from '../middleware/fetchapi';
import Modalpopup from '../layouts/dashboard/Modalpopup'
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'email', label: `email`, alignRight: false },
  { id: 'isauthorized', label: 'authorized', alignRight: false },
  { id: '', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Appname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function User() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [modaldata, setmodaldata] = useState([]);
  const [modalpage, setmodalpage] = useState('');
  const handleClose = () => setOpen(false);
  const [isloadpage, setloadpage] = useState(true);
  const handleUpdate = () => setloadpage(true);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Appname');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { enqueueSnackbar } = useSnackbar();

  const handleDelOpen = (e) => {
    setmodaldata(data.filter((item) => item._id === e)[0])
    setmodalpage('AdminFormdel')
    setOpen(true)
  };

  const USERLIST = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  const updatelist = () => {
    fetchget('/admin/list').then((response) => {
      const { unauthenticated } = response
      if (unauthenticated) { enqueueSnackbar(response.message, { variant: response.variant }); logout(); navigate('/') }
      setdata(response.list);
      setloadpage(false)
    })
  }

  const setauthorized = (_id, isauthorized) => {
    const data = { _id, isauthorized }
    fetchpost('/admin/setauthorized', data).then((response) => {
      enqueueSnackbar(response.message, { variant: response.variant })
      handleUpdate()
    })
  }

  const handleUnlock = (_id) => {
    const data = { _id }
    fetchpost('/admin/unlock', data).then((response) => {
      enqueueSnackbar(response.message, { variant: response.variant })
      handleUpdate()
    })
  }

  const handleRecovery = (_id) => {
    const data = { _id }
    fetchpost('/admin/recovery', data).then((response) => {
      enqueueSnackbar(response.message, { variant: response.variant })
      handleUpdate()
    })
  }

  if (isloadpage) { updatelist() }

  return (
    <Page title="App">
      <Container>
        <Modalpopup modalpage={modalpage} modaldata={modaldata} modalopen={open} modalhandleClose={handleClose} onSubmited={handleUpdate} />
        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} newitem={
            <Stack direction='row' align='right'>
              <IconButton onClick={() => navigate(`/web/Admin`)} >
                <Iconify icon="simple-icons:nodered" width={20} height={20} />
              </IconButton>
            </Stack>
          } />
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, email, isauthorized, recovery, retry } = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">
                          <Stack direction="row">
                            <Typography variant="subtitle2" noWrap>
                              {email}
                            </Typography>
                          </Stack>

                        </TableCell>

                        <TableCell align="left">
                          <Switch
                            checked={isauthorized}
                            onChange={() => setauthorized(_id, !isauthorized)}
                            name="isadminuser"
                            color="primary"
                          />
                        </TableCell>

                        <TableCell align='right'>
                          {(retry > 4) ? (
                            <IconButton color="error" onClick={() => { handleUnlock(_id) }}>
                              <Iconify icon="fa-solid:lock" width={20} height={20} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => { enqueueSnackbar(`User is not locked`, { variant: 'warning' }) }}>
                              <Iconify icon="fa-solid:unlock" width={20} height={20} />
                            </IconButton>
                          )}
                          {recovery ? (
                            <IconButton color="error" onClick={() => { handleRecovery(_id) }}>
                              <Iconify icon="bi:send-check-fill" width={20} height={20} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => { enqueueSnackbar(`No password request from user`, { variant: 'warning' }) }}>
                              <Iconify icon="bi:send-dash-fill" width={20} height={20} />
                            </IconButton>
                          )}
                          <IconButton onClick={() => { handleDelOpen(_id) }}>
                            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page >
  );
}

export default User;