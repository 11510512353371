import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
  TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {RecordDrawer} from '../sections/@dashboard/record'
import Modalpopup from '../layouts/dashboard/Modalpopup'
import { fetchget } from '../middleware/fetchapi';
// mock
// import USERLIST from '../_mock/user';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'UPSName', label: 'UPSName', alignRight: false },
  { id: 'Created', label: 'Created', alignRight: false },
  { id: 'Filename', label: 'Filename', alignRight: false },  
  
];

// ----------------------------------------------------------------------

function filterfunction (list,filterarr){  
  const filterlist = []
  const returnrow = {}
  const Nulllist = TABLE_HEAD.map(row=>{
    returnrow[row.id] = 'SearchNotFound'   
    if(list.length === 0 ) {returnrow[row.id] = 'No Records'   }     
    return returnrow
  })[0]

  list.forEach((row) => {    
  const isshow = Object.keys(filterarr).every((key) => row[key].includes(filterarr[key]))
  if(isshow){filterlist.push(row)}
  return null
})
  if(filterlist.length === 0) {return [Nulllist]}
  return filterlist
}

export default function App() {
  const [page, setPage] = useState(0);

  const [filterarray, setFilterArray] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(25);  

  const [data, setdata] = useState([]);

  const [isupdated, setupdated] = useState(false);

  const [filterrecords, setFilterFecords] = useState(data);

  const [open, setOpen] = useState(false);
  const [modaldata, setmodaldata] = useState({});
  const [modalpage, setmodalpage] = useState('');
  const handleClose = () => setOpen(false);
  const handleUpdate = () => uploadatelist();

  const handleNew = () => {
    setmodalpage('RecordForm')
    setOpen(true)
  };
  
  const handleDelOpen = (e) => {
    setmodaldata(data.filter((item) => item._id === e._id)[0])
    setmodalpage('RecordFormdel')
    setOpen(true)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlefilter = (event,id) => {    
    const newarray = filterarray
    newarray[id] = event.target.value
    if (event.target.value === '') {delete newarray[id]}
    setFilterArray(newarray)
    setFilterFecords(filterfunction(data,newarray))    
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const uploadatelist = () => {
    fetchget('/app/list').then((response) => {
      setdata(response.list)
    })
  }

  useEffect(()=>{
    if(!isupdated){
      fetchget('/app/list').then((response) => {
        setupdated(true)
        setdata(response.list)        
      })
    }
    setFilterFecords(filterfunction(data,filterarray))
  },[isupdated, data, filterarray])

  const isUserNotFound = filterrecords.length === 0;

  const filteredUsers = filterrecords

  return (
    <Page title="App">
        <Container>
        <Modalpopup modalpage={modalpage} modaldata={modaldata} modalopen={open} modalhandleClose={handleClose} onSubmited={handleUpdate} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <h2>
            Discharge Record
          </h2>
          <Button onClick={handleNew} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Record
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>          
                <TableBody>           
                  <TableRow>
                    {TABLE_HEAD.map((row)=>{
                      const {id} = row
                      return(
                        <TableCell key={`head-${id}`} >
                          <TextField label={id} variant="standard" inputProps={filterarray[id]} onChange={(e)=> {handlefilter(e,id)}}/>
                        </TableCell>
                      )
                    })}            
                    
                  </TableRow>   
                </TableBody>

                {!isUserNotFound && (
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, Site, UPSName, Filename, Created } = row;                           
                    return (
                      <TableRow
                        hover
                        key={`row-${_id}`}
                        tabIndex={-1}
                      >
                        <TableCell>{Site}</TableCell>
                        <TableCell>{UPSName}</TableCell>
                        <TableCell>{Created}</TableCell>
                        <TableCell>{Filename}</TableCell>
                      
                        <TableCell align="right">

                          <RecordDrawer reportinfo={row}/>
                          <IconButton onClick={() => { handleDelOpen(row) }}>
                            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>)}

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={'NotFound'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>        
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterrecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page >
  );
}